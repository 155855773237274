import { useContext, useEffect, useRef } from "react";
import { Button, Card, Carousel, Form, Input, Row, Select, Tag } from "antd";
import { Flex } from "../shared/global";
import {
  languageOptions,
  languageOptionsOrganisation,
  searchFilterOptions,
} from "~/utils/helpers";
import { DownloadOutlined } from "@ant-design/icons";
import { GlobalContext } from "~/context/global.context";
import { CarouselRef } from "antd/lib/carousel";
import { FormInstance } from "antd/es/form/Form";
import slugify from "slugify";
import { IVideoRequest, VideoRequestVersionColor } from "~/model/video.model";
import { ITag } from "~/model/tag.model";

type InnerProps = {
  request: IVideoRequest;
  formInstance: FormInstance;
  hasFile: boolean;
};
export default function VideoRequestInformations({
  request,
  formInstance,
  hasFile,
}: InnerProps) {
  const carouselRef = useRef<CarouselRef>(null);

  const video = request?.video;

  useEffect(() => {
    if (hasFile) {
      carouselRef.current?.goTo(1);
    } else {
      carouselRef.current?.goTo(0);
    }
  }, [hasFile]);

  return (
    <Card
      title={
        <Flex align='center' gap={12}>
          <div>{"Video Informations"}</div>
          <Tag
            color={VideoRequestVersionColor[video.versionNumber || -1]}
          >{`v${[video.versionNumber]}`}</Tag>
        </Flex>
      }
    >
      <Carousel ref={carouselRef}>
        <DisplayRequestInformations request={request} />
        <FormRequest form={formInstance} request={request} />
      </Carousel>
    </Card>
  );
}

const DisplayRequestInformations = ({
  request,
}: {
  request: IVideoRequest;
}) => {
  const video = request?.video;

  return (
    <div>
      <p>
        <b>{"Language: "}</b>
        {languageOptions.find((lng) => lng.value === video.language)?.label ||
          "N/A"}
      </p>
      <p>
        <b>{"Title: "}</b>
        {request.video.title || "N/A"}
      </p>
      <p>
        <b>{"DOI: "}</b>
        {video.doi ? (
          <a href={video.doi} target='_blank'>
            {video.doi}
          </a>
        ) : (
          "N/A"
        )}
      </p>
      <Button
        icon={<DownloadOutlined />}
        type='link'
        style={{ padding: 0, marginBottom: 8 }}
        href={request.pdf.url}
        target='_blank'
      >
        {"Download PDF"}
      </Button>
      <p>
        <b>{"Medical specialties: "}</b>
        <Row gutter={[8, 8]} style={{ margin: "8px 0" }}>
          {video?.medicalSpecialties?.map((ms) => (
            <Tag>{(ms as ITag).translations.en}</Tag>
          ))}
        </Row>
      </p>
      <p>
        <b>{"Tags: "}</b>
        <Row gutter={[8, 8]} style={{ margin: "8px 0" }}>
          {video?.tags?.map((tag) => (
            <Tag>
              {(tag as ITag).translations.en}
              <small>{` (${
                (
                  video.medicalSpecialties?.find(
                    (ms) =>
                      (ms as ITag)._id ===
                      ((tag as ITag).parent as unknown as string)
                  ) as ITag
                )?.translations?.en
              })`}</small>
            </Tag>
          ))}
        </Row>
      </p>
      <p>
        <b>{"Keywords: "}</b>
        <Row gutter={[8, 8]} style={{ margin: "8px 0" }}>
          {video?.keywords?.map((kw) => (
            <Tag>{kw}</Tag>
          ))}
        </Row>
      </p>
    </div>
  );
};

const FormRequest = ({
  request,
  form,
}: {
  request: IVideoRequest;
  form: FormInstance;
}) => {
  const { tagList } = useContext(GlobalContext);

  const selectedSpecialties = Form.useWatch("medicalSpecialties", form);

  return (
    <Form
      form={form}
      initialValues={{
        ...request.video,
        medicalSpecialties: request.video.medicalSpecialties?.map(
          (spe) => (spe as ITag)._id
        ),
        tags: request.video.tags?.map((tag) => (tag as ITag)._id),
      }}
    >
      <Form.Item name='language' label='Language' required>
        <Select
          placeholder='Video language'
          options={languageOptionsOrganisation(request.organisation)}
        />
      </Form.Item>
      <Form.Item name='title' label='Title' required>
        <Input placeholder='Video title' />
      </Form.Item>
      <Form.Item name='doi' label='DOI' required>
        <Input placeholder='https://doi.example.com' />
      </Form.Item>
      <Form.Item name='medicalSpecialties' label='Medical specialties' required>
        <Select
          allowClear
          mode='multiple'
          placeholder='Select medical specialties'
          options={tagList
            .filter((t) => !t.parent)
            .map((tag) => ({
              label: tag.translations.en,
              value: tag._id,
            }))}
          filterOption={searchFilterOptions}
        />
      </Form.Item>
      <Form.Item name='tags' label='Tags' required>
        <Select
          allowClear
          mode='multiple'
          placeholder='Select tags'
          options={tagList
            .filter((t) => !!t.parent)
            .filter((t) =>
              selectedSpecialties?.length
                ? selectedSpecialties?.includes(t?.parent?._id)
                : true
            )
            .map((tag) => ({
              label: (
                <span>
                  {tag.translations.en}{" "}
                  <small>{`(${tag.parent?.translations.en})`}</small>
                </span>
              ),
              name: tag.translations.en,
              value: tag._id,
            }))}
          filterOption={(input: string, option) => {
            if (!option?.label) return false;
            return option.name
              .toLocaleLowerCase()
              .includes(slugify(input).toLocaleLowerCase());
          }}
        />
      </Form.Item>
      <Form.Item name='keywords' label='Keywords' required>
        <Select
          allowClear
          mode='tags'
          placeholder='Enter keywords'
          filterOption={searchFilterOptions}
          tokenSeparators={[","]}
          notFoundContent={null}
        />
      </Form.Item>
    </Form>
  );
};
