import { FormInstance, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditionPageHeader, {
  PageHeaderSetContent,
} from "~/components/EditionPageHeader.tsx";
import { getTranslatedArticleFromDoi } from "~/services";
import ArticleEditionTranslate from "./ArticleEditionTranslate";
import { ContentFormatsEnum } from "~/model/enum";
import { ArticleTranslationResponse, IArticle } from "~/model/article.model";

type ArticleEditionHeaderProps = {
  form: FormInstance;
  article: IArticle | null;
  setArticle: (article: IArticle | null) => void;
};
export default function ArticleEditionHeader({
  form,
  article,
  setArticle,
}: ArticleEditionHeaderProps) {
  const { id: articleId } = useParams();
  const navigate = useNavigate();
  const [translatedArticles, setTranslatedArticles] =
    useState<ArticleTranslationResponse | null>(null);

  useEffect(() => {
    async function getTranslations() {
      if (!article) return;

      const translations = await getTranslatedArticleFromDoi(article.doi);
      if (translations) setTranslatedArticles(translations);
    }

    getTranslations();
  }, [article]);

  if (!article) return null;

  return (
    <EditionPageHeader
      format={ContentFormatsEnum.ARTICLE}
      form={form}
      content={article || undefined}
      setContent={setArticle as PageHeaderSetContent}
    >
      <ArticleEditionTranslate
        article={article}
        setArticle={setArticle}
        translatedArticles={translatedArticles}
        setTranslatedArticles={setTranslatedArticles}
      />

      <Select
        style={{ width: 120, marginLeft: 10 }}
        placeholder='Translated version'
        defaultValue={articleId}
        disabled={!translatedArticles}
        loading={!translatedArticles}
        options={translatedArticles?.map((art) => ({
          label: `Version ${art.language.toUpperCase()}`,
          // value: art._id,
          // disabled: art._id === article._id,
          value: art.slug,
          disabled: art.slug === article.slug,
        }))}
        onChange={(value) => {
          setArticle(null);
          navigate(`/content-management/article/${value}`, {
            replace: true,
            state: {
              translatedArticles,
            },
          });
        }}
      />
    </EditionPageHeader>
  );
}
