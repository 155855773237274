/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Image, Upload, Button, Input, Modal } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { pasteAndClearLineBreaks } from "~/utils/helpers";
import { IImage } from "~/model/image.model";

interface DragImageUploader extends UploadProps {
  images?: IImage[];
  defaultImages?: IImage[];
  deleteFile: (name: string) => void;
  inputLegendImage: (legend: string, imageName: string) => void;
  onOrderChange?: (images: IImage[]) => void;
}

const DragImageUploader: React.FC<DragImageUploader> = ({
  images,
  defaultImages,
  name,
  deleteFile,
  inputLegendImage,
  onOrderChange,
  onChange,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    images
      ? images.map((image) => ({
          uid: image._id,
          name: image.path,
          status: "done",
          url: image.url,
          legend: image.legend,
        }))
      : []
  );
  const [inputValues, setInputValues] = useState<
    { legend: string | undefined; imageName: string }[]
  >(
    images
      ? images.map((image) => ({
          legend: image.legend,
          imageName: image.path,
        }))
      : []
  );
  const [preventPreview, setPreventPreview] = useState(false);
  const [selectedModal, setSelectedModal] = useState<string | null>(null);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const filteredFileList = newFileList
      .filter((el) => !images?.find((i) => i.path === el.name))
      .filter((el) => !el?.url) as any;

    onChange && onChange(filteredFileList);
  };

  const onDragChange = (
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    if (sourceIndex === targetIndex) return;

    setPreventPreview(true);
    const nextState = swap(fileList, sourceIndex, targetIndex);
    setFileList(nextState);
    onOrderChange &&
      onOrderChange(
        nextState.map(
          (file) =>
            ({
              ...file,
              order: nextState.findIndex((el) => el.uid === file.uid),
            } as any)
        )
      );

    setTimeout(() => {
      setPreventPreview(false);
    }, 100);
  };

  const handleDelete = (file: UploadFile<any>) => {
    const newFileList = fileList.filter((el) => el !== file);
    setFileList(newFileList);

    if (file.url) deleteFile(file.name);

    onChange && onChange(newFileList as any);

    setInputValues((prev) => prev.filter((el) => el.imageName !== file.name));
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    file: UploadFile<any>
  ) => {
    if (inputValues.some((el) => el.imageName === file.name))
      setInputValues((prev) => {
        const newInputValues = [...prev];
        const index = newInputValues.findIndex(
          (el) => el.imageName === file.name
        );
        newInputValues[index] = {
          legend: e.target.value,
          imageName: file.name,
        };
        return newInputValues;
      });
    else
      setInputValues([
        ...inputValues,
        {
          legend: e.target.value,
          imageName: file.name,
        },
      ]);
  };

  useEffect(() => {
    if (defaultImages?.length === fileList.length)
      setFileList(
        defaultImages.map((image) => ({
          uid: image._id,
          name: image.path,
          status: "done",
          url: image.url,
          legend: image.legend,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultImages]);

  const uploadButton = (
    <div style={{ padding: 48 }}>
      <PlusOutlined />
      <div>{"Upload"}</div>
    </div>
  );

  return (
    <>
      <GridContextProvider onChange={onDragChange}>
        <StyledWrapper>
          <GridDropZone
            id='items'
            boxesPerRow={4}
            rowHeight={216}
            style={{
              height: 216 * Math.ceil(fileList.length / 4),
            }}
            disableDrag={!!selectedModal}
          >
            {fileList.map((file) => (
              <GridItem key={file.uid}>
                <div className='grid-item'>
                  <div className='upload-render'>
                    <Button
                      size='small'
                      shape='circle'
                      className='delete-button'
                      icon={<CloseOutlined />}
                      onClick={() => handleDelete(file)}
                    />
                    <Image
                      width='100%'
                      height={150}
                      preview={!preventPreview}
                      src={
                        file.url
                          ? file.url
                          : file.originFileObj
                          ? URL.createObjectURL(file.originFileObj)
                          : file.thumbUrl
                      }
                      alt={file.name}
                    />

                    <Modal
                      title='Add a legend'
                      open={selectedModal === file.name}
                      onOk={() => {
                        inputLegendImage(
                          inputValues.find((el) => el.imageName === file.name)
                            ?.legend || "",
                          file.name
                        );
                        setSelectedModal(null);
                      }}
                      onCancel={() => setSelectedModal(null)}
                      destroyOnClose
                    >
                      <Input.TextArea
                        placeholder={"Legend image"}
                        style={{ width: "100%" }}
                        onPaste={pasteAndClearLineBreaks}
                        autoSize={{ minRows: 5, maxRows: 20 }}
                        value={
                          inputValues.find((el) => el.imageName === file.name)
                            ?.legend
                        }
                        onChange={(e) => handleChangeInput(e, file)}
                      />
                    </Modal>

                    <Button
                      type='link'
                      onClick={() => setSelectedModal(file.name)}
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100%",
                        display: "block",
                        marginTop: 8,
                        padding: 0,
                      }}
                    >
                      {inputValues.find((el) => el.imageName === file.name)
                        ?.legend ||
                        (file as any)?.legend ||
                        "Add a legend"}
                    </Button>
                  </div>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </StyledWrapper>
      </GridContextProvider>
      <StyledUpload
        showUploadList={false}
        name={name}
        accept='.jpg, .jpeg, .png'
        listType='picture-card'
        fileList={fileList}
        onChange={handleChange}
        customRequest={(payload) => {
          if (payload.onSuccess) payload.onSuccess("ok");
        }}
      >
        {uploadButton}
      </StyledUpload>
    </>
  );
};

export default DragImageUploader;

const StyledUpload = styled(Upload)`
  display: block;
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  margin: 16px auto;

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 75px;
  }
`;

const StyledWrapper = styled.div`
  .grid-item {
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .upload-render {
    position: relative;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    .delete-button {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 10;
    }

    img {
      object-fit: cover;
    }
  }
`;
