import { TagsOutlined } from "@ant-design/icons";
import { FormInstance, message, Select } from "antd";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import { ITag } from "~/model/tag.model";
import { searchFilterOptions } from "~/utils/helpers";

type SelectTagsFieldProps = {
  form: FormInstance;
  mapKey?: keyof ITag;
};
export default function SelectTagsField({
  form,
  mapKey = "_id",
}: SelectTagsFieldProps) {
  const { tagList } = useContext(GlobalContext);

  function handleSelect(value: string) {
    const tag = tagList.find((tag) => String(tag[mapKey]) === value);
    if (
      !form
        .getFieldValue("medical_specialties")
        .includes(String(tag?.parent?.[mapKey]))
    ) {
      form.setFieldsValue({
        medical_specialties: [
          ...form.getFieldValue("medical_specialties"),
          String(tag?.parent?.[mapKey]),
        ],
      });
      message.success({
        icon: <TagsOutlined />,
        content: `Added ${tag?.parent?.translations.en} to Medical Specialties`,
      });
    }
  }

  const options = tagList
    .filter((tag) => Object.keys(tag).includes("parent"))
    .map((tag) => ({
      label: (
        <span>
          {tag.translations.en}{" "}
          <small>{`(${tag?.parent?.translations.en})`}</small>
        </span>
      ),
      value: String(tag[mapKey]),
      name: tag.translations.en,
    }));

  return (
    <Select
      mode='multiple'
      placeholder='Tags'
      options={options}
      defaultValue={form.getFieldValue("tags")}
      filterOption={searchFilterOptions}
      onSelect={handleSelect}
      onChange={(value) => form.setFieldsValue({ tags: value })}
    />
  );
}
