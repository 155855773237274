import slugify from "slugify";

import { DefaultOptionType } from "antd/lib/select";
import { message } from "antd";
import { AxiosError } from "axios";
import {
  ContentFormatsEnum,
  IUserRole,
  OrganisationUid,
  PermissionEnum,
  PermissionLabel,
  SupportedLanguage,
} from "~/model/enum";
import { IOrganisation } from "~/model/organisation.model";
import { IUserAdmin } from "~/model/user.model";
import { IContent } from "~/model/content.model";

export const languageLabel = {
  [SupportedLanguage.EN]: "English",
  [SupportedLanguage.FR]: "French",
  [SupportedLanguage.IT]: "Italian",
  [SupportedLanguage.ES]: "Spanish",
  [SupportedLanguage.DE]: "German",
  [SupportedLanguage.PT]: "Portugese",
  [SupportedLanguage.AR]: "Arabic",
};

export const languageFlag = {
  [SupportedLanguage.EN]: "🇬🇧",
  [SupportedLanguage.FR]: "🇫🇷",
  [SupportedLanguage.IT]: "🇮🇹",
  [SupportedLanguage.ES]: "🇪🇸",
  [SupportedLanguage.DE]: "🇩🇪",
  [SupportedLanguage.PT]: "🇵🇹",
  [SupportedLanguage.AR]: "🇦🇪",
};

export const languageOptions = [
  { value: SupportedLanguage.EN, label: "🇬🇧 English" },
  { value: SupportedLanguage.FR, label: "🇫🇷 French" },
  { value: SupportedLanguage.IT, label: "🇮🇹 Italian" },
  { value: SupportedLanguage.ES, label: "🇪🇸 Spanish" },
  { value: SupportedLanguage.DE, label: "🇩🇪 German" },
  { value: SupportedLanguage.PT, label: "🇵🇹 Portugese" },
  { value: SupportedLanguage.AR, label: "🇦🇪 Arabic" },
];

export function languageOptionsOrganisation(organisation?: IOrganisation) {
  const allowedLanguages = organisation?.organisationSettings.allowedLanguages;

  const languages = Object.keys(SupportedLanguage)
    .filter((lng) =>
      allowedLanguages?.includes(lng.toLowerCase() as SupportedLanguage)
    )
    .map((lng) => ({
      value: lng.toLowerCase(),
      label: languageLabel[lng.toLocaleLowerCase() as SupportedLanguage],
    }));

  return languages;
}

export const checkPermission = (
  user: IUserAdmin,
  permissionLabel: PermissionLabel,
  permission?: PermissionEnum
) => {
  if (!user) return false;
  if (user?.roles?.includes(IUserRole.SUPER_ADMIN)) return true;

  return user?.adminPermissions
    ?.find((permission) => permission.label === permissionLabel)
    ?.permissions.some(
      (subPermission) => subPermission === (permission || PermissionEnum.Read)
    );
};

export function searchFilterOptions(
  input?: string,
  option?: DefaultOptionType
) {
  // Reminder: use key 'name' if label is a ReactNode

  if ((!option?.children && !option?.label) || !input) return false;

  let children;
  if (option.children)
    children = slugify(option.children as unknown as string).toLowerCase();
  else if (option.name)
    children = slugify(option.name as unknown as string).toLowerCase();
  else if (option.label)
    children = slugify(option.label as unknown as string).toLowerCase();

  if (!children) return false;

  return children.includes(slugify(input).toLowerCase());
}

export function getPermissionsOrganisation(organisation?: IOrganisation) {
  const organisationPermissions = [
    PermissionLabel.Content,
    PermissionLabel.SensitiveData,
  ];

  if (!!organisation?.uid && organisation.uid !== OrganisationUid.JUISCI)
    return organisationPermissions;

  return Object.values(PermissionLabel);
}

export function pathToSlug(path: string) {
  const newPath = path.replace(/\//g, "-").replace(/^-|-$/g, "");
  return slugify(newPath, { lower: true });
}

export function copyTextToClipboard(text: string) {
  // Vérifier si l'API Clipboard est disponible dans le navigateur
  if (!navigator.clipboard) {
    console.error("L'API Clipboard n'est pas disponible dans ce navigateur.");
    return;
  }

  // Copier le texte dans le presse-papiers
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success("Text copied to clipboard");
    })
    .catch((error) => {
      console.error(
        "Erreur lors de la copie du texte dans le presse-papiers :",
        error
      );
    });
}

export const secondsToMMSS = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

interface ClipboardEvent extends React.ClipboardEvent<HTMLTextAreaElement> {
  clipboardData: DataTransfer;
}
export const pasteAndClearLineBreaks = (e: ClipboardEvent) => {
  e.preventDefault();
  const text = e.clipboardData.getData("text/plain");
  const noLineBreaksText = text.replace(/(\r\n|\n|\r)/gm, " ");
  document.execCommand("insertText", false, noLineBreaksText);
};

export function pasteAndSeparateByComma(e: ClipboardEvent) {
  e.preventDefault();
  const text = e.clipboardData.getData("text/plain");
  const noLineBreaksText = text.replace(/(\r\n|\n|\r)/gm, ", ");
  document.execCommand("insertText", false, noLineBreaksText);
}

export function getRequestErrorMessage(error: unknown) {
  return (
    (error as AxiosError<{ message: string }>)?.response?.data?.message ||
    (error as Error)?.message
  );
}
export const getContentType = (content: IContent) => {
  if ("article_type" in content) return ContentFormatsEnum.ARTICLE;
  if ("playlist" in content) return ContentFormatsEnum.PLAYLIST;
  return content.content_format;
};

export const getContentLink = (content: IContent) => {
  switch (getContentType(content)) {
    case ContentFormatsEnum.ARTICLE:
      return `/content-management/article/${content.slug}`;

    case ContentFormatsEnum.PLAYLIST:
      return `/content-management/playlist/${content._id}`;

    case ContentFormatsEnum.VIDEO:
      return `/content-management/video/${content.slug}`;

    case ContentFormatsEnum.NECTAR:
      return `/content-management/nectar/${content.id}`;

    case ContentFormatsEnum.INFOGRAPHIC:
      return `/content-management/infographic/${content._id}`;

    default:
      return "/";
  }
};
