import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  notification,
} from "antd";

import {
  deleteParsing,
  deleteParsingBatch,
  listParsings,
  postRetryAllParsing,
  postRetryParsing,
} from "../../services";
import { IQueryParsing } from "../../model/query.model";
import lodash from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { Flex } from "../../components/shared/global";
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { IParsing } from "~/model/parser.model";

const FILTERS_STATUS = ["Pending", "Success", "Duplicate", "Failure"];

export const ParsingList: React.FC<{ batchMode?: boolean }> = (pageProps) => {
  const navigate = useNavigate();
  const params = useParams<{ batchName: string }>();
  const [innerParsingList, setInnerParsingList] = useState<IParsing[]>([]);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchParams, setFetchParams] = useState<IQueryParsing>({
    limit: 50,
    offset: 0,
    sortBy: "creationDate",
    sortOrder: "desc",
  });

  const [selectedParsingRows, setSelectedParsingRows] = useState<IParsing[]>(
    []
  );

  const fetchParsings = async () => {
    setIsLoading(true);

    const { docs: parsingList, meta } = await listParsings({
      ...fetchParams,
      batchName: params.batchName || undefined,
    });

    if (parsingList.length) {
      setInnerParsingList(parsingList);
    }

    if (meta.total) {
      setCount(meta.total);
    }
    setIsLoading(false);
  };

  const handleCreateNewParsing = () => {
    navigate("/content-sources/parsing/create/");
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      setIsLoading(true);
      setPage(page);
      setFetchParams({
        ...fetchParams,
        limit: pageSize,
        offset: (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize,
      });
      setIsLoading(false);
    }
  };

  const handleFilterChange = (status?: IParsing["meta"]["status"]) => {
    selectedParsingRows.length && setSelectedParsingRows([]);
    setFetchParams({
      ...fetchParams,
      status: status,
    });
  };

  const handleSortChange = (value?: "asc" | "desc") => {
    setFetchParams({
      ...fetchParams,
      sortBy: "creationDate",
      sortOrder: value || "desc",
    });
  };

  function filterInnerParsing(innerParsingList: IParsing[]): IParsing[] {
    const uniqueBatchNames: { [batchName: string]: boolean } = {};

    return innerParsingList.filter((parsing) => {
      if (!parsing?.batchName) return true;
      if (parsing?.batchName && !(parsing.batchName in uniqueBatchNames)) {
        uniqueBatchNames[parsing?.batchName] = true;
        return true;
      }
      return false;
    });
  }

  const deleteSelectedParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        await Promise.all(
          selectedParsingRows.map(async (parsing) => {
            if (parsing.batchName && !params.batchName)
              await deleteParsingBatch(parsing.batchName);
            else await deleteParsing(parsing.id);
          })
        );
        notification.success({
          message: "Parsings deleted successfully",
        });
        setSelectedParsingRows([]);
        fetchParsings();
      },
    });
  };

  const handleRetryParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to retry the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await Promise.all(
            selectedParsingRows.map(async (parsing) => {
              await postRetryParsing(parsing.taskId);
            })
          );
          notification.success({
            message: "Parsings re-tried successfully",
          });

          setSelectedParsingRows([]);
          fetchParsings();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            notification.error({
              message: "An error occurred while retrying parsing",
              description: error?.response?.data.message || "Unknown error",
            });
          }
        }
      },
    });
  };

  const handleRetryAllParsings = async () => {
    try {
      await postRetryAllParsing();
      notification.success({
        message: "All parsings re-tried successfully",
      });
    } catch (error) {
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred while retrying parsing",
          description: error?.response?.data.message || "Unknown error",
        });
    }
  };

  useEffect(() => {
    fetchParsings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams, params.batchName]);

  const columns: ColumnsType<IParsing> = [
    {
      title: pageProps.batchMode ? "Parsing name" : "Batch / Parsing Name",
      dataIndex: "type",
      key: "type",
      width: "55%",
      render: (_, parsing) => {
        if (parsing?.batchName && !pageProps.batchMode)
          return (
            <Link
              to={`/content-sources/parsing/batch/${encodeURIComponent(
                parsing.batchName
              )}`}
            >
              <Flex justify='start' align='center' gap={4}>
                <Tag color='blue'>{"🗃️"}</Tag>
                <span style={{ fontWeight: 700 }}>{parsing.batchName}</span>
              </Flex>
            </Link>
          );
        else
          return (
            <Link to={`/content-sources/parsing/${parsing.id}`}>
              <Flex justify='start' align='center' gap={4}>
                <Tag color='orange'>{"📄"}</Tag>
                <span>
                  {parsing?.article
                    ? parsing.article.title
                    : (parsing as IParsing).pdfName || parsing.doi}
                </span>
              </Flex>
            </Link>
          );
      },
    },
    {
      title: "Status",
      dataIndex: "pdfName",
      key: "pdfName",
      render: (text: string) => {
        const parsing = lodash.find(innerParsingList, { pdfName: text });
        if (parsing) {
          return (
            <Tag
              icon={
                parsing?.meta?.status === "success" ? (
                  <CheckOutlined />
                ) : parsing?.meta?.status === "failure" ? (
                  <CloseOutlined />
                ) : parsing?.meta?.status === "duplicate" ? (
                  <WarningOutlined />
                ) : (
                  <SyncOutlined spin />
                )
              }
              color={
                (parsing as IParsing)?.meta?.status === "success"
                  ? "success"
                  : (parsing as IParsing)?.meta?.status === "failure"
                  ? "error"
                  : (parsing as IParsing)?.meta?.status === "duplicate"
                  ? "blue"
                  : "warning"
              }
            >
              {(parsing as IParsing)?.meta?.status.toLocaleUpperCase()}
            </Tag>
          );
        }
      },
    },
    {
      title: "Article status",
      dataIndex: ["article", "meta", "status"],
      key: "articleStatus",
      render: (articleStatus: "draft" | "published", parsing: IParsing) =>
        parsing?.batchName && !location.pathname.includes("batch") ? (
          "N/A"
        ) : articleStatus === "draft" ? (
          <Tag color='orange'>{"Draft"}</Tag>
        ) : articleStatus === "published" ? (
          <Tag color='green'>{"Published"}</Tag>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Creation date",
      dataIndex: "meta",
      key: "meta",
      render: (meta: {
        creationDate: Date;
        lastModified: Date;
        status: "draft" | "published";
      }) => {
        return new Date(meta.creationDate).toLocaleDateString();
      },
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "calc(100vh - 66px)",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        {pageProps.batchMode ? (
          <Button
            icon={<ArrowLeftOutlined />}
            type='link'
            style={{ padding: 0 }}
            onClick={() => navigate("/content-sources/parsing")}
          >
            {"Back to parsings list"}
          </Button>
        ) : (
          <Button />
        )}
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
            {pageProps.batchMode
              ? `Batch parsing "${params.batchName}"`
              : "Parsing list"}
          </h1>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Flex gap={8} align='center' style={{ width: "fit-content" }}>
              {!selectedParsingRows.length ? (
                <Button
                  icon={<SyncOutlined />}
                  disabled={
                    !innerParsingList.some((el) => el.meta.status === "failure")
                  }
                  onClick={handleRetryAllParsings}
                >
                  {"Retry All Failed"}
                </Button>
              ) : (
                <Button
                  icon={<SyncOutlined />}
                  disabled={
                    !selectedParsingRows.some(
                      (el) => el.meta.status === "failure"
                    )
                  }
                  onClick={handleRetryParsings}
                >
                  {"Retry selected"}
                </Button>
              )}

              {selectedParsingRows.length && (
                <Button
                  type='primary'
                  onClick={deleteSelectedParsings}
                  disabled={!selectedParsingRows.length}
                  danger
                >
                  Delete selected
                </Button>
              )}
              <Divider type='vertical' />
            </Flex>
            {!pageProps.batchMode && (
              <Button
                type='primary'
                onClick={handleCreateNewParsing}
                style={{ marginRight: 10 }}
              >
                New parsing
              </Button>
            )}

            <Divider type='vertical' />
            <div style={{ color: "#000", fontWeight: 500 }}>
              Filter by status:
            </div>
            <Select
              placeholder='Status'
              style={{ width: 120 }}
              options={FILTERS_STATUS.map((status) => ({
                label: status,
                value: status.toLocaleLowerCase(),
              }))}
              onChange={handleFilterChange}
              allowClear
            />
            <Divider type='vertical' />
            <div style={{ color: "#000", fontWeight: 500 }}>
              Sort by creation date
            </div>
            <Select
              placeholder='Sort by creation date'
              defaultValue={"desc"}
              style={{ width: 120 }}
              options={[
                {
                  label: "Ascending",
                  value: "asc",
                },
                {
                  label: "Descending",
                  value: "desc",
                },
              ]}
              onChange={handleSortChange}
            />
          </div>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={isLoading} tip='Loading...'>
            <Table
              size='small'
              columns={columns}
              dataSource={(params.batchName
                ? innerParsingList
                : filterInnerParsing(innerParsingList)
              ).map((parsing) => ({ ...parsing, key: parsing.id }))}
              rowSelection={{
                selectedRowKeys: selectedParsingRows.map(
                  (parsing) => parsing.id
                ),
                onChange: (_: React.Key[], selectedRows) => {
                  setSelectedParsingRows(selectedRows);
                },
                getCheckboxProps: (parsing) => ({
                  name: parsing.batchName,
                }),
              }}
              pagination={false}
              scroll={{
                x: "max-infographic",
                y: "calc(100vh - 330px)",
              }}
              sticky
            />
          </Spin>
          <Pagination
            style={{ marginTop: "10px" }}
            onChange={handlePaginationChange}
            total={count}
            current={page}
            defaultCurrent={1}
            defaultPageSize={50}
            pageSizeOptions={["50", "75", "100"]}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};
