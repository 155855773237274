import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Modal,
  Row,
  Spin,
  Select,
  Card,
  Tag,
} from "antd";

import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";

import {
  updateTag,
  listTagsFromParent,
  createTag,
  deleteTag,
  getTag,
  listParentTags,
  getProfessionList,
  associateTagToProfessions,
} from "~/services";
import { ICreateTagDto } from "~/model/dto//tag-dto";
import slugify from "slugify";
import { useNavigate, useParams } from "react-router-dom";
import LoadingLayout from "~/components/shared/LoadingLayout";
import {
  getRequestErrorMessage,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { Flex, Spacer } from "~/components/shared/global";
import { Link } from "react-router-dom";
import { ITag } from "~/model/tag.model";
import { IProfession } from "~/model/profession.model";
import { SupportedLanguage } from "~/model/enum";

const { confirm } = Modal;

export const TagEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const tagId = params.id;
  const [innerTag, setInnerTag] = useState<ITag>();
  const [innerTagList, setInnerTagList] = useState<ITag[]>([]);
  const [innerTagListFromParent, setInnerTagListFromParent] = useState<ITag[]>(
    []
  );
  const [tagSaving, setTagSaving] = useState<boolean>(false);
  const [professions, setProfessions] = useState<{
    data: IProfession[];
    meta: { total: number; offset: number; limit: number };
  } | null>(null);
  const [form] = Form.useForm();
  const [isSpecialty, setIsSpecialty] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchTag = async () => {
      const tagList = await listParentTags();
      if (tagList) setInnerTagList(tagList);

      if (tagId) {
        const tag = await getTag(tagId);

        if (tag) {
          const tagListFromParent = await listTagsFromParent(tag.uid);
          if (tagListFromParent) setInnerTagListFromParent(tagListFromParent);
        }

        setInnerTag(tag);
        setIsSpecialty(!tag.parent);
      }
    };
    fetchTag();
  }, [tagId]);

  useEffect(() => {
    const fetchProfessions = async () => {
      const { docs: data, meta } = await getProfessionList({
        offset: 0,
        limit: 100,
      });
      if (meta.total > 100)
        notification.warning({
          message: "Professions limit reached",
          description: "Only the first 100 professions are displayed.",
        });

      setProfessions({ data, meta });
    };

    fetchProfessions();
  }, []);

  const handleReturn = () => {
    navigate(-1);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Tag saved",
      description: "The tag was succesfully saved.",
    });
  };

  const associateToProfessions = async (tagId: string) => {
    const professionIds = form.getFieldValue("professions");

    if (!professions) return;

    setTagSaving(true);

    // for loop to associate the tag to the professions
    try {
      await associateTagToProfessions({
        tagId,
        professionIds,
      });
      notification.success({
        message: "Medical specialty updated",
        description:
          "Professions succesfully associated to the medical specialty",
      });
    } catch (error) {
      notification.error({
        message: "An error has occured",
        description: getRequestErrorMessage(error),
      });
    }

    setTagSaving(false);
  };

  const handleSubmit = async (
    values: ICreateTagDto & { professions?: string[] }
  ) => {
    setTagSaving(true);

    delete values.professions;

    if (tagId) {
      const updatedTag = await updateTag(tagId, {
        ...values,
        uid: tagId,
      });

      if (updatedTag) setInnerTag(updatedTag);

      associateToProfessions(updatedTag._id);

      navigate(`/reference-data/tag/${updatedTag.uid}`, { replace: true });
    } else {
      const createdTag = await createTag({
        ...values,
        uid: slugify(values.translations[SupportedLanguage.EN], {
          lower: true,
          strict: true,
        }),
      });
      if (createdTag) {
        navigate(`/reference-data/tag/${createdTag.uid}`, { replace: true });
      }
      // associate the tag/specialty to the professions
      associateToProfessions(createdTag._id);
    }
    setTagSaving(false);
    openNotificationSuccess();
  };

  const handleDeletion = async () => {
    if (tagId)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this article?</p>,
        async onOk() {
          const deletedArticle = await deleteTag(tagId);
          if (deletedArticle) {
            navigate("/reference-data/tag/");
          }
        },
        onCancel() {},
      });
  };

  if (!innerTag && params.id) return <LoadingLayout />;

  const initialTranslations = languageOptions.reduce(
    (acc, { value }) => {
      acc["translations"] = {
        ...(acc["translations"] || {}),
        [value]: innerTag?.translations[value as SupportedLanguage] || "",
      };
      return acc;
    },
    { translations: {} }
  );

  return (
    <div className='basic-layout'>
      <Button
        onClick={handleReturn}
        type='link'
        icon={<LeftOutlined />}
        style={{ padding: 0 }}
      >
        {"Back"}
      </Button>

      <Spacer height={16} />
      <Flex align='center' justify='space-between'>
        <h1
          style={{
            fontWeight: 800,
            fontSize: "30px",
            margin: 0,
            width: "100%",
          }}
        >
          {tagId ? "Edit" : "Create"}
          {innerTag?.parent ? " tag" : " medical specialty"}
        </h1>
        <Flex justify='end' gap={16}>
          <Button type='primary' onClick={() => form.submit()}>
            {tagId ? "Save changes" : "Create"}
          </Button>
          {tagId ? (
            <Button danger onClick={handleDeletion}>
              Delete tag
            </Button>
          ) : null}
        </Flex>
      </Flex>
      <Spacer />
      <Spin spinning={tagSaving} tip='Saving in progress...'>
        <Form
          layout='vertical'
          name='tag_edition'
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            ...initialTranslations,
            parent: innerTag?.parent?.uid,
            professions: professions?.data
              .filter((p) => p.medical_specialties.find((m) => m.uid === tagId))
              .map((p) => p._id),
          }}
        >
          <Row justify='space-between' gutter={16}>
            <Col span={12}>
              <Card title='Translations'>
                {languageOptions.map(({ value, label }) => (
                  <Form.Item
                    key={value}
                    label={label}
                    name={["translations", value]}
                    required={true}
                  >
                    <Input placeholder={"Tag name"} />
                  </Form.Item>
                ))}
              </Card>
            </Col>
            <Col span={12}>
              {!tagId ? (
                <>
                  <Card title='Select between Medical specialty or Tag'>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => setIsSpecialty(value)}
                      placeholder='Select a type'
                    >
                      <Select.Option value={true}>
                        Medical specialty
                      </Select.Option>
                      <Select.Option value={false}>Tag</Select.Option>
                    </Select>
                  </Card>
                  <Spacer />
                </>
              ) : null}

              {isSpecialty === null ? null : isSpecialty ? (
                <Card title='Associated professions'>
                  <Form.Item name='professions'>
                    <Select
                      mode='multiple'
                      allowClear
                      placeholder='Select Professions'
                      options={professions?.data.map((profession) => ({
                        value: profession._id,
                        label: profession.translations.en,
                      }))}
                      showSearch
                      filterOption={searchFilterOptions}
                      tagRender={(props) => (
                        <Tag
                          closable={props.closable}
                          onClose={props.onClose}
                          color='orange'
                          style={{ margin: 4 }}
                        >
                          <Link
                            to={`/reference-data/profession/${props.value}`}
                          >
                            {props.label}
                          </Link>
                        </Tag>
                      )}
                    />
                  </Form.Item>
                </Card>
              ) : (
                <Card title={"Parent medical specialty"}>
                  <Form.Item key={"parent"} name={"parent"}>
                    <Select
                      showSearch
                      placeholder={"Select a Medical Specialty"}
                      filterOption={searchFilterOptions}
                      options={innerTagList.map((tag: ITag) => ({
                        label: tag.translations.en,
                        value: tag.uid,
                      }))}
                    />
                  </Form.Item>
                </Card>
              )}
              <Spacer height={16} />
              {isSpecialty && tagId ? (
                <Card title='Associated tags'>
                  {innerTagListFromParent?.length ? (
                    <ul>
                      {innerTagListFromParent.map((tag: ITag) => (
                        <li>
                          <a href={`/reference-data/tag/${tag.uid}`}>
                            {tag.translations.en}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No associated tags for now.</p>
                  )}
                </Card>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};
