import { TagsOutlined } from "@ant-design/icons";
import { Card, Divider, Table, Tabs } from "antd";
import { useContext, useState } from "react";
import { Flex } from "~/components/shared/global";
import LoadingLayout from "~/components/shared/LoadingLayout";
import { GlobalContext } from "~/context/global.context";
import { CONTENT_TYPES_COL, CONTENT_TYPES_TABS } from "./constant";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { ITag } from "~/model/tag.model";
import { IContent } from "~/model/content.model";
import { ContentFormatsEnum } from "~/model/enum";

const PerformanceMetricsTypes = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const { monitoring } = useContext(GlobalContext);
  const [selectedTab, setSelectedTab] = useState<ContentFormatsEnum>(
    (searchParams.get("type") as ContentFormatsEnum) ||
      ContentFormatsEnum.ARTICLE
  );

  if (!monitoring) return <LoadingLayout />;

  const renderTitle = (title: string) => {
    return (
      <Flex align='center' justify='start' gap={8}>
        <TagsOutlined />
        <div>{title}</div>
      </Flex>
    );
  };

  const sortBySpecialties = (a: { specialty: ITag }, b: { specialty: ITag }) =>
    a?.specialty?.translations?.en.localeCompare(
      b?.specialty?.translations?.en
    );

  const getContentPath = (content: IContent) => {
    switch (capitalize(selectedTab)) {
      case ContentFormatsEnum.ARTICLE:
        return `/content-management/article/${content.slug}`;

      case ContentFormatsEnum.VIDEO:
        return `/content-management/video/${content.slug}`;

      case ContentFormatsEnum.NECTAR:
        return `/content-management/nectar/${content.id}`;

      case ContentFormatsEnum.PLAYLIST:
        // TODO: rename playlistId to id
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return `/content-management/playlist/${(content as any).playlistId}`;

      default:
        return "/";
    }
  };

  const formatDataSpecialties = (
    data: Array<{ specialty: ITag; path?: string }>
  ) =>
    data
      ?.filter((item) => item?.specialty)
      ?.sort(sortBySpecialties)
      .map((item) => ({
        key: item.specialty._id,
        ...item,
        path: getContentPath(item as unknown as IContent),
      }));

  return (
    <div className='basic-layout'>
      <h1>{"Performance Metrics: Content types"}</h1>
      <Divider />
      <Tabs
        // TODO: update the URL when the tab is changed
        centered
        defaultActiveKey={capitalize(selectedTab)}
        onChange={(activeKey) => {
          const activeKeyLower = activeKey.toLowerCase();
          setSelectedTab(activeKeyLower as ContentFormatsEnum);
          navigate("?type=" + activeKeyLower, { replace: true });
        }}
        items={CONTENT_TYPES_TABS.map((tab) => ({
          key: tab.type,
          label: tab.title,
          children: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <Card
                title={renderTitle(
                  `Most viewed ${capitalize(tab.key)} by Medical Specialty`
                )}
              >
                <Table
                  size='small'
                  columns={CONTENT_TYPES_COL}
                  dataSource={formatDataSpecialties(
                    monitoring[tab.key]?.bySpecialty
                  )}
                />
              </Card>

              <Card
                title={renderTitle(
                  `Most viewed ${capitalize(tab.key)} by Tags`
                )}
              >
                <Table
                  size='small'
                  columns={CONTENT_TYPES_COL}
                  dataSource={[]}
                />
              </Card>

              <Card
                title={renderTitle(
                  `Listing of ${capitalize(tab.key)} ranked by the most viewed`
                )}
              >
                <Table
                  size='small'
                  columns={CONTENT_TYPES_COL}
                  dataSource={[]}
                />
              </Card>
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default PerformanceMetricsTypes;
