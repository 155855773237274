import { useLocation } from "react-router-dom";
import { pathToSlug } from "./helpers";
import { LocalStorageKeys } from "./constants";

export function useCheckFiltersSaved() {
  const location = useLocation();
  const saves = JSON.parse(
    localStorage.getItem(LocalStorageKeys.FILTERS_DRAWER) || "{}"
  );
  const slugPath = pathToSlug(location.pathname);

  return !!saves[slugPath];
}
