import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./components/LoginPage";

import "./App.css";
import { ArticleList } from "./pages/articles/list/ArticleList";
import { ArticleEdition } from "./pages/articles/edit/ArticleEdition";
import { JournalList } from "./components/JournalList";
import { JournalEdition } from "./components/JournalEdition";
import { UserList } from "./pages/users/UserList";
import { ParsingList } from "./pages/parsings/ParsingList";
import { ParsingEdition } from "./components/ParsingEdition";
import { TagList } from "./pages/specialties/TagList";
import { CrawlingEdition } from "./components/CrawlingEdition";
import { CrawlingList } from "./components/CrawlingList";
import { CompanyList } from "./components/CompanyList";
import { CompanyEdition } from "./components/CompanyEdition";
import { OrganisationList } from "./components/OrganisationList";
import { OrganisationEdition } from "./components/OrganisationEdition";
import { PlaylistEdition } from "./pages/playlists/edit/PlaylistEdition";
import { InfographicList } from "./components/InfographicList";
import { InfographicEdition } from "./pages/infographics/InfographicEdition";
import { VideoList } from "./components/VideoList";
import { VideoEdition } from "./pages/videos/edit/VideoEdition";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./store/actions";
import {
  FETCH_COMPANIES,
  FETCH_HIGHLIGHT_NECTARS,
  FETCH_JOURNALS,
  FETCH_ORGANISATIONS,
  FETCH_ROOMS,
  FETCH_TAGS,
} from "./store/types";
import { IReduxStore } from "./store/reducers";
import { GlobalContext } from "./context/global.context";
import { getMe } from "./services";

import { CongressList } from "./components/CongressList";
import { CongressEdition } from "./components/CongressEdition";
import VideoRequestList from "./pages/videos/requests/VideoRequestList";
import VideoRequestReview from "./pages/videos/requests/VideoRequestReview";
import LemonRequestList from "./components/LemonRequestList";
import NectarList from "./pages/nectars/NectarList";
import NectarEdition from "./pages/nectars/edit/NectarEdition";
import NectarQuestionPage from "./pages/nectars/NectarQuestionPage";
import AppLayout from "./components/layout/AppLayout";
import AnnouncementEdition from "./components/AnnouncementEdition";
import RolePermissionList from "./components/RolePermissionList";
import FeaturesManager from "./components/FeaturesManager";
import OrganisationLanguages from "./components/OrganisationLanguages";
import RoomEdition from "./pages/rooms/RoomEdition";
import ProfessionEdition from "./components/ProfessionEdition";
import ProfessionList from "./components/ProfessionList";
import RoomsList from "./pages/rooms/RoomsList";
import NotificationList from "./pages/notifications/NotificationList";
import NotificationEdition from "./pages/notifications/NotificationEdition";
import QuizzList from "./pages/quizz/QuizzList";
import QuizzEdition from "./pages/quizz/QuizzEdition";
import PerformanceMetricsContent from "./pages/performance/PerformanceMetricsContent";
import PerformanceMenu from "./pages/performance/PerformanceMenu";
import PerformanceMetricsUsers from "./pages/performance/PerformanceMetricsUsers";
import PerformanceMetricsUsersDetail from "./pages/performance/PerformanceMetricsUsersDetail";
import PerformanceMetricsTypes from "./pages/performance/PerformanceMetricsTypes";
import ContentMonitoringTabs from "./pages/monitoring/ContentMonitoringTabs";
import ContentMonitoringArticles from "./pages/monitoring/ContentMonitoringArticles";
import ContentMonitoringVideos from "./pages/monitoring/ContentMonitoringVideos";
import ContentMonitoringNectars from "./pages/monitoring/ContentMonitoringNectars";
import ContentMonitoringPlaylists from "./pages/monitoring/ContentMonitoringPlaylists";
import ContentMonitoringInfographics from "./pages/monitoring/ContentMonitoringInfographics";
import { MonitoringProvider } from "./context/monitoring.context";
import { TagEdition } from "./pages/specialties/TagEdition";
import { PlaylistList } from "./pages/playlists/PlaylistList";
import { LocalStorageKeys } from "./utils/constants";
import { IUserAdmin } from "./model/user.model";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: IReduxStore) => state);
  const [user, setUser] = useState<IUserAdmin | null>(null);

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageKeys.TOKEN_EXPIRATION_TIME);
    setUser(null);
  };

  const updateUser = async (logout?: boolean) => {
    if (!logout) {
      try {
        const user = await getMe();
        if (user) {
          setUser(user);
        }
      } catch {
        handleLogout();
      }
    } else {
      handleLogout();
    }
  };

  const handleUpdateReducer = useCallback(() => {
    if (!user) return;
    if (!data.companyList?.length) dispatch(fetchData(FETCH_COMPANIES));
    if (!data.journalList?.length) dispatch(fetchData(FETCH_JOURNALS));
    if (!data.tagList?.length) dispatch(fetchData(FETCH_TAGS));
    if (!data.organisationList?.length)
      dispatch(fetchData(FETCH_ORGANISATIONS));
    if (!data.highlightNectars) dispatch(fetchData(FETCH_HIGHLIGHT_NECTARS));
    if (!data.roomList) dispatch(fetchData(FETCH_ROOMS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleUpdateReducer();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [handleUpdateReducer]);

  return (
    <GlobalContext.Provider
      value={{
        ...data,
        user,
        updateUser,
      }}
    >
      <BrowserRouter>
        <AppLayout>
          <Routes>
            <Route path='/' element={<LoginPage />} />
            {/* CONTENT SOURCES */}
            <Route
              path='/content-sources'
              element={<Navigate to='/content-sources/crawling' replace />}
            />
            <Route
              path='/content-sources/crawling'
              element={<CrawlingList />}
            />
            <Route
              path='/content-sources/crawling/create'
              element={<CrawlingEdition />}
            />
            <Route
              path='/content-sources/crawling/:id'
              element={<CrawlingEdition />}
            />
            <Route path='/content-sources/parsing' element={<ParsingList />} />
            <Route
              path='/content-sources/parsing/batch/:batchName'
              element={<ParsingList batchMode={true} />}
            />
            <Route
              path='/content-sources/parsing/create'
              element={<ParsingEdition />}
            />
            <Route
              path='/content-sources/parsing/:id'
              element={<ParsingEdition />}
            />

            {/* CONTENT MANAGEMENT */}
            <Route
              path='/content-management'
              element={<Navigate to='/content-management/article' replace />}
            />
            <Route
              path='/content-management/article'
              element={<ArticleList />}
            />
            <Route
              path='/content-management/article/create'
              element={<ArticleEdition />}
            />
            <Route
              path='/content-management/article/:id'
              element={<ArticleEdition />}
            />
            <Route path='/content-management/nectar' element={<NectarList />} />
            <Route
              path='/content-management/nectar/create'
              element={<NectarEdition />}
            />
            <Route
              path='/content-management/nectar/:id'
              element={<NectarEdition />}
            />
            <Route
              path='/content-management/nectar/question/:id'
              element={<NectarQuestionPage />}
            />
            <Route path='/content-management/video' element={<VideoList />} />
            <Route
              path='/content-management/video/create'
              element={<VideoEdition />}
            />
            <Route
              path='/content-management/video/requests'
              element={<VideoRequestList />}
            />
            <Route
              path='/content-management/video/requests/:id'
              element={<VideoRequestReview />}
            />
            <Route
              path='/content-management/video/:id'
              element={<VideoEdition />}
            />
            <Route
              path='/content-management/infographic'
              element={<InfographicList />}
            />
            <Route
              path='/content-management/infographic/create'
              element={<InfographicEdition />}
            />
            <Route
              path='/content-management/infographic/:id'
              element={<InfographicEdition />}
            />
            <Route
              path='/content-management/playlist'
              element={<PlaylistList />}
            />
            <Route
              path='/content-management/playlist/create'
              element={<PlaylistEdition />}
            />
            <Route
              path='/content-management/playlist/:id'
              element={<PlaylistEdition />}
            />
            <Route path='/content-management/quizz' element={<QuizzList />} />
            <Route
              path='/content-management/quizz/create'
              element={<QuizzEdition />}
            />
            <Route
              path='/content-management/quizz/:id'
              element={<QuizzEdition />}
            />

            {/* REFERENCE DATA */}
            <Route
              path='/reference-data'
              element={<Navigate to='/reference-data/company' replace />}
            />
            <Route path='/reference-data/company' element={<CompanyList />} />
            <Route
              path='/reference-data/company/create'
              element={<CompanyEdition />}
            />
            <Route
              path='/reference-data/company/:id'
              element={<CompanyEdition />}
            />
            <Route path='/reference-data/congress' element={<CongressList />} />
            <Route
              path='/reference-data/congress/create'
              element={<CongressEdition />}
            />
            <Route
              path='/reference-data/congress/:id'
              element={<CongressEdition />}
            />
            <Route path='/reference-data/tag' element={<TagList />} />
            <Route path='/reference-data/tag/create' element={<TagEdition />} />
            <Route path='/reference-data/tag/:id' element={<TagEdition />} />
            <Route path='/reference-data/journal' element={<JournalList />} />
            <Route
              path='/reference-data/journal/create'
              element={<JournalEdition />}
            />
            <Route
              path='/reference-data/journal/:id'
              element={<JournalEdition />}
            />
            <Route
              path='/reference-data/profession'
              element={<ProfessionList />}
            />
            <Route
              path='/reference-data/profession/:id'
              element={<ProfessionEdition />}
            />
            <Route
              path='/reference-data/profession/create'
              element={<ProfessionEdition />}
            />
            {/* SENSITIVE DATA */}
            <Route
              path='/sensitive-data'
              element={<Navigate to='/sensitive-data/organisations' replace />}
            />
            <Route
              path='/sensitive-data/organisations'
              element={<OrganisationList />}
            />
            <Route
              path='/sensitive-data/organisations/create'
              element={<OrganisationEdition />}
            />
            <Route
              path='/sensitive-data/organisations/:id'
              element={<OrganisationEdition />}
            />
            <Route path='/sensitive-data/users' element={<UserList />} />
            <Route
              path='/sensitive-data/users/lemon-check-requests'
              element={<LemonRequestList />}
            />
            <Route
              path='/sensitive-data/notifications'
              element={<NotificationList />}
            />
            <Route
              path='/sensitive-data/notifications/create'
              element={<NotificationEdition />}
            />
            <Route
              path='/sensitive-data/announcements'
              element={<AnnouncementEdition />}
            />
            <Route
              path='/sensitive-data/features-manager'
              element={<FeaturesManager />}
            />
            <Route
              path='/sensitive-data/organisation-languages'
              element={<OrganisationLanguages />}
            />
            <Route path='/sensitive-data/rooms' element={<RoomsList />} />
            <Route path='/sensitive-data/rooms/:id' element={<RoomEdition />} />
            <Route
              path='/sensitive-data/rooms/create'
              element={<RoomEdition />}
            />

            {/* ANALYTICS */}
            <Route
              path='/analytics'
              element={<Navigate to='/analytics/performance-metrics' replace />}
            />
            <Route
              path='/analytics/performance-metrics'
              element={
                <Navigate to='/analytics/performance-metrics/users' replace />
              }
            />

            <Route
              path='/analytics/performance-metrics/*'
              element={
                <>
                  <PerformanceMenu />
                  <Routes>
                    <Route path='users' element={<PerformanceMetricsUsers />} />
                    <Route
                      path='users/:type'
                      element={<PerformanceMetricsUsersDetail />}
                    />
                    <Route
                      path='contents'
                      element={<PerformanceMetricsContent />}
                    />
                    <Route path='types' element={<PerformanceMetricsTypes />} />
                  </Routes>
                </>
              }
            />

            <Route
              path='/analytics/content-monitoring'
              element={
                <Navigate to='/analytics/content-monitoring/articles' replace />
              }
            />

            <Route
              path='/analytics/content-monitoring/*'
              element={
                <>
                  <MonitoringProvider>
                    <ContentMonitoringTabs />
                    <Routes>
                      <Route
                        path='articles'
                        element={<ContentMonitoringArticles />}
                      />
                      <Route
                        path='videos'
                        element={<ContentMonitoringVideos />}
                      />
                      <Route
                        path='nectars'
                        element={<ContentMonitoringNectars />}
                      />
                      <Route
                        path='playlists'
                        element={<ContentMonitoringPlaylists />}
                      />
                      <Route
                        path='infographics'
                        element={<ContentMonitoringInfographics />}
                      />
                    </Routes>
                  </MonitoringProvider>
                </>
              }
            />

            {/* SUPER ADMIN */}
            <Route
              path='/super-admin'
              element={<Navigate to='/super-admin/roles-permissions' replace />}
            />
            <Route
              path='/super-admin/roles-permissions'
              element={<RolePermissionList />}
            />
          </Routes>
        </AppLayout>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
};

export default App;
