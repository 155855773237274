import { PresetColorTypes } from "antd/lib/_util/colors";
import { IContent } from "./content.model";
import {
  FeedbackStatusEnum,
  SupportedLanguage,
  VideoFormatEnum,
  VideoRequestStatus,
} from "./enum";
import { IImage } from "./image.model";
import { IOrganisation } from "./organisation.model";
import { ITag } from "./tag.model";

export interface IApiVideo {
  title?: string;
  language?: SupportedLanguage;
  videoId?: string;
  thumbnail?: string;
  player?: string;
}

export interface IVideo extends IContent {
  sourceURL: string;
  embedURL: string;
  videoFormat: VideoFormatEnum;
  apiVideo?: IApiVideo;
  thumbnail: IImage;
}

export interface IVideoRequestFeedback {
  createdAt: Date;
  message: string;
  _id: string;
  timer: string;
  status: FeedbackStatusEnum;
  authorRole: "juisci" | "admin_org";
}

export interface IVideoRequest {
  _id: string;
  id: string;
  createdAt: string;
  name: string;
  organisation: IOrganisation;
  pdf: {
    path: string;
    url: string;
  };
  status: VideoRequestStatus;
  reason?: string;
  video: IApiVideo & {
    videoContent: IVideo;
    feedbacks: Array<IVideoRequestFeedback>;
    versionNumber?: number;
    doi: string;
    medicalSpecialties: ITag[] | string[];
    tags: ITag[] | string[];
    keywords?: string[];
  };
}

export const VideoRequestVersionColor = [
  ...PresetColorTypes.filter((color) => !["yellow", "lime"].includes(color)),
].reverse();
