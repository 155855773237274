import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Input, Table, Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "~/context/global.context";
import { useDispatch } from "react-redux";
import { fetchData } from "~/store/actions";
import { FETCH_TAGS } from "~/store/types";
import { ColumnsType } from "antd/lib/table";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { Flex, Spacer } from "~/components/shared/global";
import { ITag } from "~/model/tag.model";

export const TagList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tagList } = useContext(GlobalContext);
  const [searchValue, setSearchValue] = useState<string>();
  const [activeTab, setActiveTab] = useState<string>("tab-specialties");

  const handleClick = () => {
    navigate("/reference-data/tag/create/");
  };

  useEffect(() => {
    if (!tagList) dispatch(fetchData(FETCH_TAGS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<ITag> = [
    {
      title: "Name",
      key: "name",
      render: (_, tag) => (
        <Link to={`/reference-data/tag/${tag.uid}`}>{tag.translations.en}</Link>
      ),
    },
    {
      title: "Parent",
      key: "parent",
      render: (_, tag) =>
        tag.parent ? (
          <Link to={`/reference-data/tag/${tag.parent.uid}`}>
            {tag.parent.translations.en}
          </Link>
        ) : (
          "N/A"
        ),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex justify='space-between' align='center'>
        <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
          {"Medical specialties & Tags"}
        </h1>
        <Button
          type='primary'
          onClick={handleClick}
          icon={<AppstoreAddOutlined />}
        >
          {"Create new Tag or Specialty"}
        </Button>
      </Flex>

      <Card>
        <Tabs
          centered
          items={[
            { label: "Medical specialties", key: "tab-specialties" },
            { label: "Tags", key: "tab-tags" },
          ]}
          onChange={(key) => setActiveTab(key)}
        />
        <Input.Search
          placeholder='Search tag'
          allowClear
          onSearch={setSearchValue}
        />
        <Spacer />
        <Table
          loading={!tagList.length}
          columns={columns}
          dataSource={tagList
            .filter((v) => (activeTab === "tab-tags" ? v.parent : !v.parent))
            .filter((v) =>
              v.translations.en
                .toLowerCase()
                .startsWith(searchValue?.toLowerCase() || "")
            )}
          scroll={{
            x: "max-infographic",
            y: "calc(100vh - 300px)",
          }}
          sticky
        />
      </Card>
    </div>
  );
};
