import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  message,
  notification,
} from "antd";
import {
  deleteArticleFromRoom,
  deleteContentFromRoom,
  deletePinnedContentFromRoom,
  deletePlaylistFromRoom,
  postArticleToRoom,
  postContentToRoom,
  postPinnedContentToRoom,
  postPlaylistToRoom,
} from "~/services";
import { isAxiosError } from "axios";
import { Flex, Spacer } from "./global";
import { GlobalContext } from "~/context/global.context";
import {
  languageLabel,
  languageOptions,
  searchFilterOptions,
} from "~/utils/helpers";
import { LinkOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { fetchData } from "~/store/actions";
import { FETCH_ROOMS } from "~/store/types";
import { useNavigate } from "react-router-dom";
import { ContentFormatsEnum, SupportedLanguage } from "~/model/enum";

type RoomsAssociatorProps = {
  contentId: string;
  language: SupportedLanguage;
  isPublic?: boolean;
  ownerId?: string;
  type: ContentFormatsEnum | "playlist";
  defaultValue?: string[];
  form: FormInstance;
};

const RoomsAssociator = ({
  contentId,
  language,
  type,
  isPublic: defaultIsPublic,
  ownerId,
  form,
}: RoomsAssociatorProps) => {
  const { roomList: roomListReducer, organisationList } =
    useContext(GlobalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRooms, setSelectedRooms] = useState<string[]>([]); // To associate
  const [pinnedRoom, setPinnedRoom] = useState<string>(); // To be pinned
  const [selectedLanguage, setSelectedLanguage] = useState<
    SupportedLanguage | undefined
  >(); // To be pinned
  const [isPublic, setIsPublic] = useState<boolean>(defaultIsPublic || false);
  const [innerOwner, setInnerOwner] = useState<string | null>(ownerId || null);

  const handleAssociateRooms = async () => {
    if (!contentId) return;

    try {
      message.loading("Associating rooms...");
      let defaultSelected;

      switch (type) {
        case ContentFormatsEnum.ARTICLE:
          defaultSelected =
            roomList
              ?.filter((r) =>
                (r.articles as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];

          for await (const roomId of selectedRooms)
            if (!defaultSelected.includes(roomId))
              await postArticleToRoom(roomId, contentId);
          for await (const roomId of defaultSelected)
            if (!selectedRooms.includes(roomId))
              await deleteArticleFromRoom(roomId, contentId);

          break;

        case "playlist":
          defaultSelected =
            roomList
              ?.filter((r) =>
                (r.playlists as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];

          for await (const roomId of selectedRooms)
            if (!defaultSelected.includes(roomId))
              await postPlaylistToRoom(roomId, contentId);
          for await (const roomId of defaultSelected)
            if (!selectedRooms.includes(roomId))
              await deletePlaylistFromRoom(roomId, contentId);
          break;

        default:
          defaultSelected =
            roomList
              ?.filter((r) =>
                (r.contents as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];

          for await (const roomId of selectedRooms)
            if (!defaultSelected.includes(roomId))
              await postContentToRoom(roomId, contentId);
          for await (const roomId of defaultSelected)
            if (!selectedRooms.includes(roomId))
              await deleteContentFromRoom(roomId, contentId);
          break;
      }

      message.destroy();

      notification.success({
        message: "Rooms associated",
        description: `The rooms have been associated to the ${type}.`,
      });
    } catch (error) {
      let description = "An error occured while associating the rooms.";
      if (isAxiosError(error))
        description = error.response?.data?.message || message;

      notification.error({
        message: "Error",
        description,
      });
    }
  };

  const handlePinRooms = async () => {
    if (!contentId || !selectedLanguage || !pinnedRoom) return;

    const _triggeRequest = async () => {
      message.loading("Pinning rooms...");

      await postPinnedContentToRoom({
        contentId,
        roomId: pinnedRoom,
        language: selectedLanguage,
      });

      await dispatch(fetchData(FETCH_ROOMS));

      message.destroy();

      setSelectedLanguage(undefined);

      notification.success({
        message: "Rooms pinned",
        description: `The content has been pinned to the room in ${selectedLanguage} language.`,
      });
    };

    try {
      // Verify if the room has already a content pinned in the selected language

      const alreadyPinnedContent = roomList
        ?.find((r) => r.id === pinnedRoom)
        ?.pinnedContents.find((c) => c.language === selectedLanguage);

      if (alreadyPinnedContent)
        Modal.confirm({
          title: "Warning - Room already pinned",
          content: (
            <div>
              <p>
                {`The room has already a content pinned in ${selectedLanguage} language.`}
              </p>
            </div>
          ),
          onOk: async () => {
            await _triggeRequest();
          },
          onCancel: () => message.destroy(),
        });
      else await _triggeRequest();
    } catch (error) {
      let description = "An error occured while pinning the rooms.";
      if (isAxiosError(error))
        description = error.response?.data?.message || message;

      notification.error({
        message: "Error",
        description,
      });
    }
  };

  const handleDeletePinnedRoom = async (
    roomId: string,
    language: SupportedLanguage
  ) => {
    try {
      message.loading("Unpinning content...");
      await deletePinnedContentFromRoom({ roomId, contentId, language });
      await dispatch(fetchData(FETCH_ROOMS));
      message.destroy();

      notification.success({
        message: "Content unpinned",
        description: `The content has been unpinned from the room.`,
      });
    } catch (error) {
      let description = "An error occured while unpinning the content.";
      if (isAxiosError(error))
        description = error.response?.data?.message || message;

      notification.error({
        message: "Error",
        description,
      });
    }
  };

  useEffect(() => {
    const fetchAllRooms = async () => {
      if (!roomList) return;

      let defaultSelected: string[] = [];

      switch (type) {
        case ContentFormatsEnum.ARTICLE:
          defaultSelected =
            roomList
              .filter((r) =>
                (r.articles as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];

          break;

        case "playlist":
          defaultSelected =
            roomList
              .filter((r) =>
                (r.playlists as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];
          break;

        default:
          defaultSelected =
            roomList
              .filter((r) =>
                (r.contents as unknown as string[]).includes(contentId)
              )
              .map((r) => r.id) || [];

          break;
      }

      setSelectedRooms(defaultSelected);
    };

    fetchAllRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomListReducer]);

  useEffect(() => {
    form.setFieldValue("owner", innerOwner || null);

    console.log("innerOwner", innerOwner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerOwner]);

  if (!contentId || !roomListReducer)
    return (
      <Flex justify='center' flexDirection='column' align='center'>
        <Spacer />
        <Spin />
      </Flex>
    );

  const isContentType =
    type !== ContentFormatsEnum.ARTICLE && type !== "playlist";

  const roomList =
    isPublic || !innerOwner
      ? roomListReducer.filter((room) =>
          room.allowedLanguages.includes(language)
        )
      : roomListReducer?.filter(
          (room) =>
            room.organisation._id === innerOwner &&
            room.allowedLanguages.includes(language)
        ) || [];

  const currentPinnedRooms = [
    ...roomList.flatMap((room) =>
      room.pinnedContents.flatMap((ct) => ({
        ...ct,
        roomId: room.id,
        roomName: room.name,
      }))
    ),
  ].filter((ct) => ct.content === contentId);

  return (
    <Flex flexDirection='column' gap={8}>
      <div style={{ width: "100%" }}>
        <Divider orientation='left'>{"Ownership properties"}</Divider>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name='owner' label='Owner'>
              <Select
                allowClear
                disabled={isPublic}
                placeholder='Select an Owner'
                defaultValue={ownerId}
                onChange={(val) => {
                  setInnerOwner(val || null);
                }}
                options={organisationList?.map((org) => ({
                  label: org.name,
                  value: org._id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='isPublic' label='Accessibility' required>
              <Select
                placeholder='Accessibility'
                defaultValue={defaultIsPublic}
                onChange={(val) => {
                  setIsPublic(val);
                  form.setFieldValue("isPublic", val);
                }}
              >
                <Select.Option value={true}>{"✅ Public"}</Select.Option>
                <Select.Option value={false}>{"🔐 Private"}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div style={{ width: "100%" }}>
        <Divider orientation='left'>{"Associate to rooms"}</Divider>
        <Flex gap={8}>
          <Select
            loading={!roomList}
            disabled={!roomList}
            mode='multiple'
            placeholder='Select a room'
            style={{ width: "100%" }}
            value={selectedRooms}
            filterOption={searchFilterOptions}
            options={roomList?.map((room) => ({
              label: `${room.name} (${room.organisation.name})`,
              value: room.id,
            }))}
            onChange={(values: string[]) => {
              setSelectedRooms(values);
            }}
          />
          <Button type='primary' onClick={handleAssociateRooms}>
            {"Confirm"}
          </Button>
        </Flex>
      </div>
      <div
        style={{
          width: "100%",
          visibility:
            isContentType && selectedRooms.length > 0 ? "visible" : "hidden",
        }}
      >
        <Divider orientation='left'>{"Pin to rooms"}</Divider>
        <Flex gap={8}>
          <Select
            loading={!roomList}
            disabled={!roomList}
            placeholder='Select a room'
            style={{ width: "100%" }}
            value={pinnedRoom}
            options={roomList
              ?.filter((room) => selectedRooms.includes(room.id))
              .map((room) => ({
                label: (
                  <div>
                    {room.name} <small>{`(${room.organisation.name})`}</small>
                  </div>
                ),
                value: room.id,
              }))}
            onChange={(value: string) => {
              setPinnedRoom(value);
            }}
          />
          <Select
            placeholder='Select a language'
            value={selectedLanguage}
            disabled={!pinnedRoom}
            style={{ width: 200 }}
            options={languageOptions.filter(
              (lang) =>
                !currentPinnedRooms
                  .map((ct) => ct.language)
                  .includes(lang.value)
            )}
            onChange={(value) => {
              setSelectedLanguage(value);
            }}
          />
          <Button type='primary' onClick={handlePinRooms}>
            {"Confirm"}
          </Button>
        </Flex>
        <Spacer height={8} />

        <Flex gap={8}>
          {currentPinnedRooms.map((ct) => (
            <Tag
              key={`link-room-${ct.roomId}`}
              closable
              onClick={() => navigate(`/sensitive-data/rooms/${ct.roomId}`)}
              onClose={(e) => {
                e?.stopPropagation();
                e?.preventDefault();

                handleDeletePinnedRoom(
                  ct.roomId,
                  ct.language as SupportedLanguage
                );
              }}
              icon={<LinkOutlined />}
            >{`${ct.roomName} (${
              languageLabel[ct.language as SupportedLanguage]
            })`}</Tag>
          ))}
        </Flex>
      </div>
    </Flex>
  );
};

export default RoomsAssociator;
