/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from "@ant-design/icons";
import { Form, FormInstance, notification } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import DragImageUploader from "~/components/shared/DragImageUploader";
import { IArticle } from "~/model/article.model";
import {
  deleteArticleImage,
  updateArticleImage,
  updateImageOrder,
  uploadArticleImage,
} from "~/services";

type InnerProps = {
  form: FormInstance;
  innerArticle: IArticle | null;
};

export type ArticleEditionImagesHandle = {
  triggerFinish: (slug: string) => void;
};

const ArticleEditionImages = forwardRef<ArticleEditionImagesHandle, InnerProps>(
  ({ form, innerArticle }, ref) => {
    const [deleteFigures, setDeleteFigures] = useState<string[]>([]);
    const [reorderFigures, setReorderFigures] = useState<any[] | null>(null);
    const [labelFigures, setLabelFigures] = useState<
      { path: string; label: string | undefined }[]
    >([]);

    const imageFileList = Form.useWatch("figures", form);

    // Gère les images relatif à l'article qu'on soit en mode création ou édition...
    async function onFinishForm(slug: string) {
      notification.info({
        key: "updateImageProgress",
        icon: <LoadingOutlined />,
        message: "Update of images in progress",
        description: "Please do not refresh the application",
        placement: "bottomRight",
        duration: 0,
      });

      try {
        // Upload images
        const files = form
          ?.getFieldsValue()
          ?.figures?.filter((file: any) => file.originFileObj)
          .filter(
            (file: any) =>
              !innerArticle?.images?.some((img) => img.path === file.name)
          );

        let newArticle = innerArticle;

        if (deleteFigures) {
          for await (const path of deleteFigures) {
            await deleteArticleImage(slug, path);
          }

          setDeleteFigures([]);
        }

        if (files)
          for await (const file of files) {
            let fileLegend = null;

            if (file.originFileObj) {
              fileLegend = labelFigures.find(
                (figure) => figure.path === file.originFileObj?.name
              )?.label;

              newArticle = await uploadArticleImage(slug, file.originFileObj);
            }

            const figureId =
              newArticle?.images[newArticle?.images.length - 1]?._id;

            // On met à jour l'ordre de l'image nouvellement ajoutée
            if (figureId && newArticle) {
              const order =
                reorderFigures?.find(
                  (figure) => figure.name === file.originFileObj?.name
                )?.order ?? newArticle?.images.length - 1;

              await updateImageOrder(figureId, order);

              setReorderFigures(
                (prev) =>
                  prev &&
                  prev?.filter(
                    (figure) => figure.name !== file.originFileObj?.name
                  )
              );

              setLabelFigures([]);
              setDeleteFigures([]);
              imageFileList;
              form.setFieldValue("figures", []);
            }

            // On met à jour la légende de l'image nouvellement ajoutée
            if (fileLegend && figureId) {
              await updateArticleImage(figureId, {
                legend: fileLegend,
              });
            }

            setLabelFigures((prev) =>
              prev.filter((figure) => figure.path !== file.originFileObj.name)
            );
          }

        if (labelFigures) {
          for await (const labelFig of labelFigures)
            if (labelFig.label && !deleteFigures.includes(labelFig.path)) {
              const figureId = newArticle?.images?.find(
                (figure) => figure.path === labelFig.path
              )?._id;
              if (figureId)
                await updateArticleImage(figureId, {
                  legend: labelFig.label,
                });
            }
        }

        if (reorderFigures) {
          for await (const figure of reorderFigures) {
            const figureId = newArticle?.images?.find(
              (el) => el.path === figure.path || el.path === figure.name
            )?._id;

            if (figureId) await updateImageOrder(figureId, figure.order);
          }
        }
      } catch (error) {
        console.error("updateFigures", error);
        throw error;
      } finally {
        notification.close("updateImageProgress");
        notification.success({
          message: "Images updated",
          description: "Images have been updated successfully",
          placement: "bottomRight",
        });
      }
    }

    useImperativeHandle(ref, () => ({
      triggerFinish: (slug: string) => onFinishForm(slug),
    }));

    if (!form) return null;

    return (
      <Form.Item name='figures'>
        <DragImageUploader
          name='file'
          deleteFile={(imagePath) =>
            setDeleteFigures([...deleteFigures, imagePath])
          }
          images={
            innerArticle?.images && imageFileList
              ? [
                  ...innerArticle.images.sort((a, b) => a.order - b.order),
                  ...imageFileList.map((file: any) => ({
                    name: file.name,
                    uid: file.uid,
                    legend: "",
                  })),
                ]
              : innerArticle?.images?.sort((a, b) => a.order - b.order)
          }
          defaultImages={innerArticle?.images}
          inputLegendImage={(legend, imagePath) => {
            if (!labelFigures.find((figure) => figure.path === imagePath)) {
              setLabelFigures([
                ...labelFigures,
                { path: imagePath, label: legend },
              ]);
            } else {
              setLabelFigures(
                labelFigures.map((figure) =>
                  figure.path === imagePath
                    ? { ...figure, label: legend }
                    : figure
                )
              );
            }
          }}
          onOrderChange={(fileList) => {
            setReorderFigures(fileList);
          }}
          onChange={(fileList) => form.setFieldValue("figures", fileList)}
        />
      </Form.Item>
    );
  }
);

export default ArticleEditionImages;
