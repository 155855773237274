import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { IArticle } from "~/model/article.model";
import { SupportedLanguage } from "~/model/enum";
import { IJournal } from "~/model/journal.model";
import { IOrganisation } from "~/model/organisation.model";
import { IRoom } from "~/model/room.model";
import { ITag } from "~/model/tag.model";
import { languageFlag } from "~/utils/helpers";

export const columns: ColumnsType<IArticle> = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 480,
    render: (_text: string, record: IArticle) => (
      // <Link to={`/content-management/article/${record._id}`}>
      <Link to={`/content-management/article/${record.slug}`}>
        {record.title}
      </Link>
    ),
  },
  {
    title: "Lang",
    dataIndex: "language",
    key: "language",
    width: 50,
    align: "center",
    render: (language: SupportedLanguage) => languageFlag[language],
  },
  {
    title: "Status",
    dataIndex: "title",
    key: "title",
    render: (_text: string, record: IArticle) => (
      <Tag
        color={
          record?.meta
            ? record?.meta?.status === "draft"
              ? "warning"
              : "success"
            : (record as IArticle & { status: string })?.status === "draft"
            ? "warning"
            : "success"
        }
      >
        {record?.meta
          ? record?.meta?.status?.toLocaleUpperCase()
          : (
              record as IArticle & { status: string }
            )?.status?.toLocaleUpperCase()}
      </Tag>
    ),
  },
  {
    title: "Access",
    dataIndex: "isPublic",
    key: "isPublic",
    width: 80,
    render: (isPublic: boolean) =>
      isPublic ? (
        <Tag color='green'>{"✅ Public"}</Tag>
      ) : (
        <Tag color='purple'>{"🔐 Private"}</Tag>
      ),
  },
  {
    title: "Owner",
    dataIndex: "owner",
    key: "owner",
    width: 150,
    render: (owner: IOrganisation) => {
      if (!owner) return <span>{"N/A"}</span>;

      return (
        <Link to={`/sensitive-data/organisations/${owner?.uid}`}>
          {owner.name}
        </Link>
      );
    },
  },
  {
    title: "Creation date",
    dataIndex: "title",
    key: "title",
    width: 140,
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (_text: string, record: IArticle) => {
      return new Date(record?.meta?.creationDate)?.toLocaleDateString();
    },
  },
  {
    title: "Publication date",
    dataIndex: "publication_date",
    key: "publication_date",
    width: 140,
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (date: string) => {
      return new Date(date).toLocaleDateString();
    },
  },
  {
    title: "Rooms",
    dataIndex: "rooms",
    key: "rooms",
    width: 200,
    render: (rooms: IRoom[]) => (
      <Flex align='center' gap={8}>
        {rooms && rooms.length > 0
          ? rooms.map((room) => (
              <Link key={room.id} to={`/sensitive-data/rooms/${room.id}`}>
                <Tag>{room.name}</Tag>
              </Link>
            ))
          : "N/A"}
      </Flex>
    ),
  },
  {
    title: "Medical Specialties",
    dataIndex: "medical_specialties",
    key: "medical_specialties",
    width: 480,
    render: (medical_specialties: Array<ITag & { en: string }>) =>
      medical_specialties
        ?.map((medicalSpecialty) => medicalSpecialty.translations.en)
        .join(", "),
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    width: 480,
    render: (tags: Array<ITag>) =>
      tags?.map((tag) => tag.translations.en).join(", "),
  },
  {
    title: "Journal",
    dataIndex: "journal",
    key: "journal",
    width: 240,
    render: (journal: IJournal | string) => {
      return typeof journal === "string" ? journal : journal?.name;
    },
  },
  {
    title: "Primary author",
    dataIndex: "primary_author",
    key: "primary_author",
    width: 240,
  },
];

export const columnsMetrics: ColumnsType<IArticle> = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 480,
    render: (_text: string, record: IArticle) => (
      <Link to={`/content-management/article/${record.slug}`}>
        {record.title}
      </Link>
    ),
  },
  {
    title: "Creation date",
    dataIndex: "title",
    key: "title",
    width: 160,
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (_text: string, record: IArticle) => {
      return new Date(record?.meta?.creationDate)?.toLocaleDateString();
    },
  },
  {
    title: "Publication date",
    dataIndex: "publication_date",
    key: "publication_date",
    width: 160,
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (date: string) => {
      return new Date(date).toLocaleDateString();
    },
  },
  {
    title: "Total Views",
    dataIndex: "metrics",
    key: "metrics",
    width: 100,
    render: (metrics: Record<string, number>) => metrics?.views,
  },
  {
    title: "Source Views",
    dataIndex: "metrics",
    key: "metrics",
    width: 120,
    render: (metrics: Record<string, number>) => metrics?.sourceViews || 0,
  },
  {
    title: "Saves",
    dataIndex: "metrics",
    key: "metrics",
    width: 100,
    render: (metrics: Record<string, number>) => metrics?.saveds,
  },
  {
    title: "Likes",
    dataIndex: "metrics",
    key: "metrics",
    width: 100,
    render: (metrics: Record<string, number>) => metrics?.likes,
  },
  {
    title: "Shares",
    dataIndex: "metrics",
    key: "metrics",
    width: 80,
    render: (metrics: Record<string, number>) => metrics?.shares,
  },
];
